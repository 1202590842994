import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import icon from "images/MP.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GoldenButton } from "./GoldenButton";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import { WalletConnectButton } from "./WalletConnectButton";
import { shortenAddress } from "utils/misc";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { showSnackbar } from "state/ui";

export const Welcome = ({ connects }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    navigate("/give-away");
  };

  const handleCopy = () => {
    dispatch(showSnackbar({ severity: "info", message: "Contract copied" }));
  };
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      marginBottom={5}
    >
      <Grid item xs={4}>
        <img src={icon} width={isMobile ? "100" : "200"} alt="MagicPenny" />
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant={isMobile ? "h5" : "h2"}
          textTransform="uppercase"
          marginBottom={2}
          sx={{
            position: "relative",
            // display: "inline-block",
            color: "black", // Main text color
            fontWeight: "bold", // Match bold style
            "&::after": {
              content: '"Welcome to Magic Penny"',
              position: "absolute",
              left: 3, // Adjust for more shadow offset
              top: 3, // Adjust for more shadow offset
              color: "#ffbf00", // Set to a custom color (e.g., gold) or theme color like secondary.main
              zIndex: -1,
            },
          }}
        >
          Welcome to Magic Penny
        </Typography>
        <Typography variant="h5" marginBottom={2}>
          The coin that unlocks through sharing.
        </Typography>
        <Box>
          {connects ? (
            <WalletConnectButton />
          ) : (
            <GoldenButton text={"Give magic Penny"} onClick={handleNavigate} />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box display={"flex"} flexDirection={"column"}>
          <Box marginBottom={2}>
            <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
              Magic Penny Verified Contract Address
            </Typography>
          </Box>
          <Box>
            <Box
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box>
                <Button
                  color="info"
                  variant="outlined"
                  component={Link}
                  target="_blank"
                  rel="no_opener"
                  style={{ borderRadius: 25, border: "2px solid" }}
                  to={`https://etherscan.io/address/${process.env.REACT_APP_BLOCKCHAIN_MAGIC_PENNY_ADDRESS}`}
                >
                  <Typography variant="body" fontWeight={"bold"}>
                    {isMobile
                      ? shortenAddress(
                          process.env.REACT_APP_BLOCKCHAIN_MAGIC_PENNY_ADDRESS
                        )
                      : process.env.REACT_APP_BLOCKCHAIN_MAGIC_PENNY_ADDRESS}
                  </Typography>
                </Button>
              </Box>
              <Box marginLeft={2}>
                <CopyToClipboard
                  text={process.env.REACT_APP_BLOCKCHAIN_MAGIC_PENNY_ADDRESS}
                >
                  <IconButton onClick={handleCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
